body {
  margin: 0;
  overflow: hidden;
}

.background {
  width: 100%;
  height: 100%;
  background: #f5f5f5;
  position: absolute;
  opacity: 0.15;
  top: 0;
  left: 0;
  overflow: hidden;
}

.background span {
  width: 20vmin;
  height: 20vmin;
  border-radius: 20vmin;
  backface-visibility: hidden;
  position: absolute;
  animation-name: move;
  animation-duration: 6s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
.background span:nth-child(1) {
  color: #FFACAC;
  top: 18%;
  left: 67%;
  animation-duration: 12.2s;
  animation-delay: -1.6s;
  transform-origin: -8vw 7vh;
  box-shadow: -40vmin 0 4.6564903099vmin currentColor;
}
.background span:nth-child(2) {
  color: #FE6B8B;
  top: 81%;
  left: 51%;
  animation-duration: 14.6s;
  animation-delay: -4s;
  transform-origin: -1vw -24vh;
  box-shadow: -40vmin 0 5.6498127727vmin currentColor;
}
.background span:nth-child(3) {
  color: #FF8E53;
  top: 93%;
  left: 83%;
  animation-duration: 14.7s;
  animation-delay: -14.1s;
  transform-origin: 2vw 13vh;
  box-shadow: 40vmin 0 3.7405594685vmin currentColor;
}
.background span:nth-child(4) {
  color: #FE6B8B;
  top: 22%;
  left: 27%;
  animation-duration: 15.8s;
  animation-delay: -14.8s;
  transform-origin: 18vw 7vh;
  box-shadow: -40vmin 0 3.3312705813vmin currentColor;
}
.background span:nth-child(5) {
  color: #FE6B8B;
  top: 26%;
  left: 92%;
  animation-duration: 13.8s;
  animation-delay: -1.2s;
  transform-origin: -4vw -4vh;
  box-shadow: 40vmin 0 3.9869943387vmin currentColor;
}
.background span:nth-child(6) {
  color: #FF8E53;
  top: 95%;
  left: 21%;
  animation-duration: 13.7s;
  animation-delay: -6.5s;
  transform-origin: 3vw -3vh;
  box-shadow: -40vmin 0 6.3370696473vmin currentColor;
}
.background span:nth-child(7) {
  color: #fe9373;
  top: 63%;
  left: 62%;
  animation-duration: 15.9s;
  animation-delay: -10.4s;
  transform-origin: -2vw -10vh;
  box-shadow: -40vmin 0 5.6299450328vmin currentColor;
}
.background span:nth-child(8) {
  color: #FF8E53;
  top: 52%;
  left: 88%;
  animation-duration: 14.5s;
  animation-delay: -8.3s;
  transform-origin: 0vw -21vh;
  box-shadow: 40vmin 0 6.0275215717vmin currentColor;
}
.background span:nth-child(9) {
  color: #FE6B8B;
  top: 17%;
  left: 57%;
  animation-duration: 11.8s;
  animation-delay: -1.9s;
  transform-origin: -19vw 24vh;
  box-shadow: 40vmin 0 2.7992271457vmin currentColor;
}
.background span:nth-child(10) {
  color: #FFACAC;
  top: 47%;
  left: 82%;
  animation-duration: 11.3s;
  animation-delay: -0.4s;
  transform-origin: -16vw -5vh;
  box-shadow: -40vmin 0 3.6896205465vmin currentColor;
}
.background span:nth-child(11) {
  color: #FE6B8B;
  top: 9%;
  left: 61%;
  animation-duration: 13.6s;
  animation-delay: -15.3s;
  transform-origin: 3vw -10vh;
  box-shadow: 40vmin 0 3.2294335166vmin currentColor;
}
.background span:nth-child(12) {
  color: #FFACAC;
  top: 63%;
  left: 72%;
  animation-duration: 15.6s;
  animation-delay: -9.2s;
  transform-origin: -1vw -5vh;
  box-shadow: 40vmin 0 6.8070065113vmin currentColor;
}
.background span:nth-child(13) {
  color: #FE6B8B;
  top: 39%;
  left: 71%;
  animation-duration: 14.3s;
  animation-delay: -11s;
  transform-origin: 22vw -7vh;
  box-shadow: -40vmin 0 3.7857967142vmin currentColor;
}
.background span:nth-child(14) {
  color: #FF8E53;
  top: 84%;
  left: 48%;
  animation-duration: 14.2s;
  animation-delay: -11.5s;
  transform-origin: -4vw -22vh;
  box-shadow: -40vmin 0 5.652035108vmin currentColor;
}
.background span:nth-child(15) {
  color: #FE6B8B;
  top: 100%;
  left: 61%;
  animation-duration: 10.2s;
  animation-delay: -1.7s;
  transform-origin: 5vw -2vh;
  box-shadow: 40vmin 0 6.5435691714vmin currentColor;
}
.background span:nth-child(16) {
  color: #FF8E53;
  top: 10%;
  left: 81%;
  animation-duration: 13.9s;
  animation-delay: -12.8s;
  transform-origin: -9vw -11vh;
  box-shadow: 40vmin 0 6.6074314616vmin currentColor;
}
.background span:nth-child(17) {
  color: #FF8E53;
  top: 32%;
  left: 4%;
  animation-duration: 10.9s;
  animation-delay: -7.7s;
  transform-origin: 14vw -2vh;
  box-shadow: 40vmin 0 7.1840750252vmin currentColor;
}
.background span:nth-child(18) {
  color: #FF8E53;
  top: 74%;
  left: 67%;
  animation-duration: 12.6s;
  animation-delay: -7.7s;
  transform-origin: 22vw -22vh;
  box-shadow: 40vmin 0 6.6199939556vmin currentColor;
}
.background span:nth-child(19) {
  color: #FE6B8B;
  top: 84%;
  left: 19%;
  animation-duration: 11.9s;
  animation-delay: -15.9s;
  transform-origin: -15vw 24vh;
  box-shadow: -40vmin 0 4.8695629908vmin currentColor;
}
.background span:nth-child(20) {
  color: #FFACAC;
  top: 1%;
  left: 28%;
  animation-duration: 12.4s;
  animation-delay: -11.5s;
  transform-origin: -22vw 14vh;
  box-shadow: 40vmin 0 4.940152848vmin currentColor;
}

@keyframes move {
  100% {
    transform: translate3d(0, 0, 1px) rotate(360deg);
  }
}
